<template>
    
    <v-container class="pb-6 pb-md-12">
        <v-row>
          <v-col class="d-flex align-end" cols="12" sm="6">
            <ToggleButtonInput
              v-model="mode"
              label="Type of Event"
              :items="[{type:'_', text: 'All'}, {type:'STEP_BASED', text: 'Steps'}, {type:'ACTIVITY_BASED', text: 'Activities'}, {type:'FITNESS', text: 'Fitness'}, {type:'WELLBEING', text: 'Wellbeing'}]"
              @input="applyFilters"
              />     

          </v-col>
          <v-col class="d-flex align-end" cols="12" sm="6">
            <v-text-field v-model="query" solo rounded hide-details class="ms-8" placeholder="Search by name" clearable  v-on:keyup.enter="applyFilters" @click:clear="query='';applyFilters()" @click:prepend="applyFilters" prepend-icon="fa-search" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-end" cols="12" sm="9">

              <v-input label="" class="mt-0 " hide-details>
                <div class="d-flex flex-row">
                  <v-checkbox v-model="tags" hide-details class="mt-0 pt-0 mr-4" value="TEAMS" label="Teams" @change="applyFilters"/>
                  <!-- <v-checkbox v-model="tags" hide-details class="mt-0 pt-0 mr-4" value="FITNESS" label="Fitness" @change="applyFilters"/> -->
                  <v-checkbox v-model="tags" hide-details class="mt-0 pt-0 mr-4" value="DAILY_TARGET" label="Daily Target" @change="applyFilters"/>
                  <v-checkbox v-model="tags" hide-details class="mt-0 pt-0 mr-4" value="MAP" label="Journeys" @change="applyFilters"/>
                  <v-checkbox v-model="tags" hide-details class="mt-0 pt-0 mr-4" value="COLLECTIVE" label="Collective" @change="applyFilters"/>
                  <!-- <v-checkbox v-model="tags" hide-details class="mt-0 pt-0 mr-4" value="WELLBEING_PACK" label="360° Wellbeing" @change="applyFilters"/>  -->
                </div>
              </v-input>      
            <!-- <ToggleButtonInput
              v-model="tags"
              multiple
              :mandatory="false"
              label="Options"
              :items="[{type:'TEAMS', text: 'Teams'}, {type:'FITNESS', text: 'Fitness'}, {type:'DAILY_TARGET', text: 'Daily Target'}, {type:'WELLBEING', text: 'Wellbeing'}]"
              @input="applyFilters"
              />      -->
          </v-col>
          <v-col class="d-flex align-end" cols="12" sm="3">
            <v-btn text small @click="clearFilters" class="mb-0"><v-icon x-small class="me-1">fa-times</v-icon> Clear</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, idx) in templates" :key="idx" cols="12" sm="6" md="4" lg="3" class="d-flex flex-column">
            <EventTemplateCard :item="item" @click="hasClickListener ? $emit('click', item) : null" :routeName="routeName" />
          </v-col>
          <v-col v-if="templates && templates.length" cols="4" class="d-flex flex-column">
            <v-card class="d-flex flex-column grey lighten-3 grow" :to="{ name: 'eventmanagerCreate', query: {templateId: 'custom', groupId: this.$route.query.groupId}}">
              <v-card-title class="grow ">Custom Challenge <v-icon color="black" small class="ml-2">fa-chevron-right</v-icon></v-card-title>
              <v-card-text>Create your own fully customizable experience.</v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="templates && !templates.length" class="text-italic">
            <p>No matching templates found.</p>
            <v-btn outlined @click="clearFilters"><v-icon small class="me-1">fa-times</v-icon> Clear Filters</v-btn>
          </v-col>
        </v-row>
    </v-container>
</template>
 
<script>
import assetsService from "@/services/assetsService";
import ToggleButtonInput from "@/components/ToggleButtonInput";
import EventTemplateCard from '@/components/templates/EventTemplateCard.vue'
import MapTemplateCard from '@/components/templates/MapTemplateCard.vue'

export default {
  name: "EventTemplateGrid",
  components: {
    ToggleButtonInput,
    EventTemplateCard,
    MapTemplateCard,
  },
  props: {
    routeName: String,
    filter: Object,
  },
  data: function() {
    return {
      templates: null,
      mode: null,
      tags: [],
      query: null,
    };
  },
  async mounted() {
    this.templates = (await assetsService.getEventTemplates()).data.data;
    if (this.filter) {
      this.tags = this.filter.tags;
    }
  },
  methods: {
    async clearFilters() {
      this.query = null;
      this.mode = null;
      this.tags = [];
      this.applyFilters();
    },
    async applyFilters() {
        let filters = [...this.tags, this.mode];
        //console.log('/// filter, tags', filters);
        this.templates = (await assetsService.getEventTemplates(filters, this.query)).data.data;
    },      
  },
  computed: {
    hasClickListener(){
      return this.$listeners && this.$listeners.click;
    }
  },
  watch: {
    filter(val) {
        console.log('/// filter, tags', val);
      this.tags = val.tags;
      this.applyFilters();
    }
  }
};
</script>

<style lang="scss">
</style>

        